import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import jwt_decode from 'jwt-decode';
import __includes from 'lodash/includes';
import { useRouter } from 'next/router';
import { createContext, useEffect, useState } from 'react';
import { apiConfig } from 'utils/login/apiConfig';
import useIsServiceEnabled from 'hooks/useIsServiceEnabled';
import { useDidomiHandlers } from './helpers/didomiHandlers';

import {
    changeEmail,
    changePassCP,
    deleteMyAccount,
    editProfileCP,
    setAccount,
    signOut,
    signUpSignInCP,
    selectAccount
} from 'utils/login/authRedirect';
import { initGlobalMatr, windowSetGlobalEvar } from 'components/3cat/Audiencies/audiencies-datalayer';

export const UserContext = createContext();

const MIN_REFRESH = 30000;
const REFRESH_MARGIN = 60000;

const defaultMsalData = {
    accessToken: null,
    name: null,
    inicial: null,
    id: null,
    email: null,
    username: null,
    surname: null,
    isAuthenticated: null,
    isWebView: null,
    state: null
};
const defaultMatrData = {
    uuid: null,
    deviceUid: null,
    deviceType: null
};
const defaultUserData = {
    ...defaultMsalData,
    ...defaultMatrData
};

let exposedUserData = { idint: null, access_token: null };

const _updateExposedUserData = function (newUserData) {
    exposedUserData.idint = newUserData.id;
    exposedUserData.access_token = newUserData.accessToken;
    windowSetGlobalEvar('eVar71', { id: exposedUserData.idint });
    windowSetGlobalEvar('eVar63', { isAuthenticated: true });
};

if (typeof window !== 'undefined') {
    window.getUser = function () {
        return exposedUserData;
    };

    windowSetGlobalEvar('eVar71');
    windowSetGlobalEvar('eVar63');
}

const UserProvider = (props) => {
    const { children } = props;
    const router = useRouter();
    const [userData, setUserData] = useState({ ...defaultUserData });
    const { instance: msalInstance, accounts: msalAccounts } = useMsal();
    const isServiceEnabled = useIsServiceEnabled('login');
    const [forceCheckIfLogged, setForceCheckIfLogged] = useState(true);
    const { isDidomiLoaded, consentChanged, getUserConsentForMatr, setUserConsentForMatr, loadMatr } = useDidomiHandlers();

    const _requestAccessToken = async () => {
        const request = {
            scopes: [...apiConfig.b2cScopes],
            forceRefresh: true,
            account: msalAccounts[0]
        };
        const token = await msalInstance
            .acquireTokenSilent(request)
            .then((response) => {
                const token = response.accessToken;

                setUserData((previousUserData) => {
                    _updateExposedUserData({ id: previousUserData.id, accessToken: token });
                    return {
                        ...previousUserData,
                        accessToken: token
                    };
                });

                return token;
            })
            .catch(() => {
                signOut();
            });

        return token;
    };

    const _silentSignOut = (userName) => {
        msalInstance.logout({
            account: msalInstance.getAccountByUsername(userName),
            onRedirectNavigate: (url) => {
                return false;
            }
        });
    };

    const _visibilityHandler = () => {
        if (document.visibilityState === 'visible') {
            setForceCheckIfLogged(true);
        }
    };

    const fillUserDataFromMatr = () => {
        const uuid = window.Matr?.getUserID() || '00000000-0000-0000-0000-00000000anon';
        const deviceUid = window.Matr?.UID || '00000000-0000-0000-0000-00000000anon';
        const deviceType = window.Matr?.getUserData()?.type || 'PC';
        initGlobalMatr({ uuid, deviceUid, deviceType });
        setUserData((previousUserData) => ({
            ...previousUserData,
            uuid,
            deviceUid,
            deviceType
        }));
    };

    useEffect(() => {
        const _loadMatr = async () => {
            const isUserAuthenticated = userData.isAuthenticated;
            const shouldAnonymousMode = !(isUserAuthenticated || (isDidomiLoaded && getUserConsentForMatr()));
            await loadMatr(shouldAnonymousMode);
        };

        // Gestió centralitzada del mode de treball de Matr (normal/anònim) en funció de:
        //  - l'estat de l'usuari (autenticat/anònim)
        //  - el consentiment de l'usuari per al Matr (un custom-vendor de Didomi)
        if (userData.isAuthenticated === null || !isDidomiLoaded) return;
        _loadMatr();
        fillUserDataFromMatr();
    }, [userData.isAuthenticated, isDidomiLoaded, consentChanged, getUserConsentForMatr, loadMatr]);

    useEffect(() => {
        document.addEventListener('visibilitychange', _visibilityHandler);
        return () => {
            document.removeEventListener('visibilitychange', _visibilityHandler);
        };
    }, []);

    useEffect(() => {
        async function checkIfLogged() {
            if (forceCheckIfLogged) {
                try {
                    const ssoSilentRequest = {
                        scopes: [...apiConfig.b2cScopes],
                        redirectUri: window.jitConfig.azureSsoSilentRedirect
                    };

                    const response = await msalInstance.ssoSilent(ssoSilentRequest);

                    const decodedAt = jwt_decode(response.accessToken);
                    if (userData.id !== null && userData.id !== decodedAt.extension_ccmaIdint) {
                        _silentSignOut(userData.email);
                    }

                    setUserData((previousUserData) => {
                        _updateExposedUserData({ id: previousUserData.id, accessToken: response.accessToken });
                        let data = {
                            ...previousUserData,
                            accessToken: response.accessToken,
                            name: decodedAt.name,
                            inicial: decodedAt.name ? decodedAt.name.substring(0, 1) : '',
                            id: decodedAt.extension_ccmaIdint,
                            email: decodedAt.email,
                            username: decodedAt.email,
                            surname: decodedAt.family_name,
                            isAuthenticated: true
                        };

                        return data;
                    });

                    selectAccount();
                } catch (err) {
                    if (userData.isAuthenticated) {
                        _silentSignOut(userData.email);
                    }
                }
            }
            setForceCheckIfLogged(false);
        }

        if (isServiceEnabled) {
            checkIfLogged();
        }
    }, [isServiceEnabled, forceCheckIfLogged]);

    useEffect(() => {
        const token = userData.accessToken;
        const isWebView = userData.isWebView;
        let tokenTimeout;
        if (token && !isWebView) {
            const decodedAt = jwt_decode(token);
            const refreshTime = decodedAt.exp * 1000 - Date.now();
            const finalRefreshTime = Math.max(refreshTime - REFRESH_MARGIN, MIN_REFRESH);

            tokenTimeout = setTimeout(() => {
                if (isServiceEnabled) {
                    _requestAccessToken();
                }
            }, finalRefreshTime);
        }
        return () => {
            clearTimeout(tokenTimeout);
        };
    }, [userData.accessToken, isServiceEnabled]);

    useEffect(() => {
        if (
            (__includes(router.asPath, 'at=') && __includes(router.asPath, 'webview')) ||
            __includes(router.asPath, 'cypress') ||
            (userData.isWebView && userData.accessToken !== null)
        ) {
            if (userData.accessToken === null) {
                const hash = router.asPath.split('#')[1];
                const parsedHash = new URLSearchParams(hash);
                const token = parsedHash.get('at');
                const decodedAt = jwt_decode(token);
                _updateExposedUserData({ id: decodedAt.extension_ccmaIdint, accessToken: token });
                setUserData((previousUserData) => {
                    return {
                        ...previousUserData,
                        accessToken: token,
                        name: decodedAt.name,
                        inicial: decodedAt.name ? decodedAt.name.substring(0, 1) : '',
                        id: decodedAt.extension_ccmaIdint,
                        email: decodedAt.email,
                        username: decodedAt.email,
                        surname: decodedAt.email,
                        isAuthenticated: true,
                        isWebView: true
                    };
                });
            }
        } else if (msalAccounts.length > 0) {
            const usuari = msalAccounts[0];
            setAccount(usuari);
            setUserData((previousUserData) => {
                return {
                    ...previousUserData,
                    name: usuari.name,
                    inicial: usuari.name ? usuari.name.substring(0, 1) : '',
                    id: usuari.idTokenClaims.extension_ccmaIdint,
                    email: usuari.username,
                    username: usuari.username,
                    surname: usuari.idTokenClaims.family_name,
                    isAuthenticated: true
                };
            });

            if (isServiceEnabled && userData.accessToken === null) {
                _requestAccessToken();
            }
        } else {
            setUserData((previousUserData) => {
                return {
                    ...previousUserData,
                    ...defaultMsalData,
                    isAuthenticated: false
                };
            });
        }
    }, [router, msalAccounts, isServiceEnabled]); //camps que forcen l'actualitzacio de dades del Context (potser cal afegir més camps més endavant)

    useEffect(() => {
        setUserData((previousUserData) => {
            return {
                ...previousUserData,
                state: null
            };
        });
        if (isServiceEnabled) {
            const callbackId = msalInstance.addEventCallback((message) => {
                if (message.eventType === EventType.LOGIN_SUCCESS && message.payload?.state) {
                    setUserData((previousUserData) => {
                        return {
                            ...previousUserData,
                            state: JSON.parse(message.payload.state)
                        };
                    });
                }
            });
            return () => {
                if (callbackId) {
                    msalInstance.removeEventCallback(callbackId);
                }
            };
        }
    }, [router, isServiceEnabled]);

    const signUp = (stateParams = null) => {
        if (isServiceEnabled) {
            stateParams ? (stateParams['router'] = router.pathname) : null;
            stateParams ? signUpSignInCP(JSON.stringify(stateParams)) : signUpSignInCP();
        }
    };
    const signIn = () => {
        const jitConfigAzureAppHost = window.jitConfig.azureAppHost;
        const jitConfigAzureClient = window.jitConfig.azureClient;

        if (isServiceEnabled) {
            window.location = `${jitConfigAzureAppHost}/oauth2/v2.0/authorize?p=B2C_1A_SIGNUPSIGNIN_1&client_id=${jitConfigAzureClient}&nonce=defaultNonce&redirect_uri=https://www.3cat.cat/3cat/&scope=openid&response_type=id_token&prompt=login&option=register`;
        }
    };
    return (
        <UserContext.Provider
            value={{
                ...userData,
                signUp,
                signIn,
                signOut,
                editProfileCP,
                changePassCP,
                changeEmail,
                deleteMyAccount
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
