import __extend from 'lodash/extend';
import __isArray from 'lodash/isArray';
import __merge from 'lodash/merge';
import __remove from 'lodash/remove';
import __some from 'lodash/some';
import __isFunction from 'lodash/isFunction';
import { useCallback, useEffect, useState } from 'react';

export const useDidomiHandlers = () => {
    const [isDidomiLoaded, setIsDidomiLoaded] = useState(false);
    const [consentChanged, setConsentChanged] = useState(false);

    const MATR_VENDOR = 'c:matr-2b3yWcPU';

    const listenerDidomiConsentChanged = function (context) {
        setConsentChanged((prev) => !prev);
    };

    const isListenerAlreadySubscribed = () => {
        if (!window.didomiEventListeners) {
            return false;
        }
        return (
            __isArray(window.didomiEventListeners) &&
            __some(
                window.didomiEventListeners,
                (listener) => listener.event === 'consent.changed' && listener.listener === listenerDidomiConsentChanged
            )
        );
    };

    const subscribeToDidomiConsentChange = () => {
        window.didomiEventListeners = window.didomiEventListeners || [];
        if (!isListenerAlreadySubscribed()) {
            window.didomiEventListeners.push({
                event: 'consent.changed',
                listener: listenerDidomiConsentChanged
            });
        }
    };

    const notifyDidomiIsLoaded = () => {
        setIsDidomiLoaded(true);
        subscribeToDidomiConsentChange();
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (!window.Didomi || Object.keys(window.Didomi).length === 0) {
                window.didomiOnReady = window.didomiOnReady || [];
                window.didomiOnReady.push(notifyDidomiIsLoaded);
            } else notifyDidomiIsLoaded();
        }
    }, []);

    const getUserConsentForMatr = useCallback(() => {
        if (!isDidomiLoaded) return;

        return window.Didomi.getUserConsentStatusForVendor(MATR_VENDOR);
    }, [isDidomiLoaded]);

    const setUserConsentForMatr = useCallback(() => {
        if (!isDidomiLoaded) return;

        const ALL_PURPOSES = ["cookies", "select_basic_ads", "create_ads_profile", "select_personalized_ads", "measure_ad_performance",
            "measure_content_performance", "market_research", "improve_products", "device_characteristics", "geolocation_data",
            "create_content_profile", "select_personalized_content", "use_limited_data_to_select_content"];
        const LI_PURPOSES = ["select_basic_ads", "measure_ad_performance", "measure_content_performance",
            "market_research", "improve_products", "use_limited_data_to_select_content"];

        const previousUserStatus = window.Didomi.getUserStatus();
        const userStatus = {
            purposes: previousUserStatus.purposes,
            vendors: previousUserStatus.vendors,
            action: 'login'
        };
        __extend(userStatus.purposes, { consent: { enabled: ALL_PURPOSES, disabled: [] } });
        __extend(userStatus.purposes, { global: { enabled: ALL_PURPOSES, disabled: [] } });
        __extend(userStatus.purposes, { legitimate_interest: { enabled: LI_PURPOSES, disabled: [] } });
        __remove(userStatus.vendors.consent.disabled, (vendor) => (vendor === MATR_VENDOR));
        __remove(userStatus.vendors.global.disabled, (vendor) => (vendor === MATR_VENDOR));
        __remove(userStatus.vendors.global_consent.disabled, (vendor) => (vendor === MATR_VENDOR));
        __merge(userStatus.vendors, { consent: { enabled: [MATR_VENDOR] } });
        __merge(userStatus.vendors, { global: { enabled: [MATR_VENDOR] } });
        __merge(userStatus.vendors, { global_consent: { enabled: [MATR_VENDOR] } });
        __merge(userStatus.vendors, { global_li: { disabled: [MATR_VENDOR] } });

        window.Didomi.setUserStatus(userStatus);
    }, [isDidomiLoaded]);

    const loadMatr = useCallback(async (shouldAnonymousMode) => {
        if (typeof window !== 'object') return;

        if (!window.Matr) {
            // Utilització del mòdul https://statics.3cat.cat/js/geoZoneHandler.js
            // per a injectar la informació de geolocalització per a la llibreria Matr
            if (__isFunction(window.geoZoneHandler?.getGeoZone)) {
                window.geoZoneHandler.getGeoZone((geoZone) => {
                    window.currentGeozone = geoZone; // emprat per window.Matr.getUAInfo (pot no existir)
                    window.zone = geoZone; // valor de fallback emprat per window.Matr.getUserData
                });
            }
            const Matr = await import('@ccma/matr');
            window.Matr = Matr.default;
            __extend(window.Matr, {
                isAnonymous: function () {
                    return true;
                }
            });
            await window.Matr?.Prepare();
        }
        if (window.Matr) {
            const wasAnonymousMode = await window.Matr.UserCookieObject?.wasAnonymous?.() !== false;
            const removeRmpCookie = !wasAnonymousMode && shouldAnonymousMode;
            if (removeRmpCookie || !shouldAnonymousMode) {
                __extend(window.Matr, {
                    isAnonymous: function () {
                        return shouldAnonymousMode;
                    }
                });
                await window.Matr?.Reload(removeRmpCookie);
            }
        }
    }, []);

    return {
        isDidomiLoaded,
        consentChanged,
        getUserConsentForMatr,
        setUserConsentForMatr,
        loadMatr
    };
};
