import { getData } from '@portal-internet/bff';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import crypto from 'crypto';
import __indexOf from 'lodash/indexOf';
import __pullAt from 'lodash/pullAt';
import __cloneDeep from 'lodash/cloneDeep';
import __get from 'lodash/get';
import __set from 'lodash/set';
import __mapKeys from 'lodash/mapKeys';
import RedlNotRenderableInfo from 'components/REDL/RedlNotRenderableInfo';

import LayoutChangesTmpStorageService from './layoutChangesTmpStorageService';

export const RedlMetadataContext = createContext({
    setRedlPageMetadata: (pathname, allData, structure) => {},
    getRedlPageMetadata: (pathname) => {},
    getRedlBlocMetadata: (pathname, blocIdx) => {},
    setRedlPageRawLayout: (pathname, rawLayout) => {},
    getRedlPageRawLayout: (pathname) => {},
    getRedlBlocRawLayout: (pathname, blocIdx) => {},
    moveRedlBloc: (pathname, blocIdx, newIndex) => {},
    addRedlBloc: (pathname, newBlock, newIndex, sources) => {},
    editRedlBloc: (pathname, newBlock, newIndex, sources) => {},
    deleteRedlBloc: (pathname, blocIdx) => {},
    setRedlNotRenderableInfo: (dataId, rootIndex, componentName) => {},
    setRedlRenderableInfo: (pathname, newStructure, isReset) => {},
    resetRedlNotRenderableInfo: () => {},
    setIsPageRawLayoutShouldBeStored: () => {},
    removeRenderThumbnail: (pathname, blocIdx) => {},
    getZonaEditable: (structure) => {}
});

const RedlMetadataProvider = (props) => {
    const [pageMetadata, setPageMetadata] = useState({});
    const [pageRawLayout, setPageRawLayout] = useState({});
    const [isPageRawLayoutShouldBeStored, setIsPageRawLayoutShouldBeStored] = useState(false);
    const [pageNotRenderableInfo, setPageNotRenderableInfo] = useState({});
    const [showNotRenderableInfo, setShowNotRenderableInfo] = useState(false);

    const resetRedlNotRenderableInfo = () => {
        setPageNotRenderableInfo({});
        setShowNotRenderableInfo(false);
    };

    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url) => {
            resetRedlNotRenderableInfo();
        };

        router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [pageRawLayout, router.events]);

    useEffect(() => {
        if (isPageRawLayoutShouldBeStored && pageRawLayout) {
            LayoutChangesTmpStorageService.save(pageRawLayout);
        }
    }, [pageRawLayout, isPageRawLayoutShouldBeStored]);

    const publicApi = useMemo(() => {
        const setRedlPageMetadata = (pathname, allData = [], structure, components, layoutURLHost) => {
            setPageMetadata((prevState) => {
                if (!prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: {
                            allData: [...allData],
                            structure: [...structure],
                            components: [...components],
                            layoutURLHost,
                            editId: 0,
                            isReset: true,
                        }
                    };
                }
                return prevState;
            });
        };

        const setRedlPageRawLayout = (pathname, rawLayout) => {
            setPageRawLayout((prevState) => {
                if (!prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: rawLayout
                    };
                }
                return prevState;
            });
        };

        const getRedlPageMetadata = (pathname) => {
            return pageMetadata[pathname];
        };

        const getRedlPageRawLayout = (pathname) => {
            return pageRawLayout[pathname];
        };

        const _findZonaEditable = (structure) => {
            let zonaEditable;
            if ( structure.length > 0 ) {
                for (var i = 0; i < structure.length; i++) {
                    const component = structure[i];
                    if ( component.name === 'ZonaEditable') {
                        component.detected = 'byrecursive';
                        zonaEditable = component;
                        break;
                    } else {
                        if ( component.children ) {
                            zonaEditable = _findZonaEditable(component.children);
                        }
                    }
                }
            }
            return zonaEditable;
        };

        const getZonaEditable = (structure) => {
            const zonaEditable = _findZonaEditable(structure);
            if ( !zonaEditable ) {
                console.log("No s'ha trobat ZonaEditable.")
            }
            return zonaEditable;
        }

        const _setZonaEditableChildren = (structure, zonaEditableChildren) => {
            const zonaEditable = getZonaEditable(structure);
            if ( !zonaEditable ) return;
            zonaEditable.setted = 'byrecursive';
            zonaEditable.children = zonaEditableChildren;
        };

        const _getRedlBlocCurrentIdx = (pathname, blocIdx) => {
            const structure = pageMetadata[pathname]?.structure;
            if (!structure) return [null, -1];

            const zonaEditable = getZonaEditable(structure);
            const zonaEditableChildren = zonaEditable?.children;
            if (!zonaEditableChildren) return [null, -1];

            return [zonaEditableChildren, __indexOf(zonaEditableChildren, zonaEditableChildren.find((element) => element.blocIdx === blocIdx))];
        };

        const removeRenderThumbnail = (pathname, blocIdx) => {
            setPageMetadata((prevState) => {
                if (prevState[pathname]) {
                    const newRenderThumbnails =  prevState[pathname].renderThumbnails.filter(num => num !== blocIdx);
                   
                    return {
                        ...prevState,
                        [pathname]: {
                            ...prevState[pathname],
                            renderThumbnails: newRenderThumbnails
                        }
                    };
                }
                return prevState;
            });
        };

        const getRedlBlocMetadata = (pathname, blocIdx) => {
            const [structure, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            if (currentIdx < 0) return;

            return structure[currentIdx];
        };

        const _addEditablePropsToBlocRawLayout = (structure, allComponentsEditableProps) => {
            if (!structure) return
            if (allComponentsEditableProps[structure.name]) { structure.editableProps = allComponentsEditableProps[structure.name]; }
            if (Array.isArray(structure.children)) {
                structure.children.map((component) => {
                    _addEditablePropsToBlocRawLayout(component, allComponentsEditableProps)
                });
            }
            return structure;
        };

        const getRedlBlocRawLayout = (pathname, blocIdx) => {
            const [, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            const rawLayout = pageRawLayout[pathname];
            const structure = rawLayout?.structure || [];
            if (currentIdx < 0 || !structure) return;

            const zonaEditable = getZonaEditable(structure);
            if (!zonaEditable) return;

            const structureWithoutEditableProps = __cloneDeep(zonaEditable.children[currentIdx]);
            return _addEditablePropsToBlocRawLayout(structureWithoutEditableProps, pageRawLayout[pathname]?.editableProps || {});
        };

        const _renderRawLayoutOnServer = async (pathname, newStructure, newAllData, isReset, renderThumbnails = []) => {
            resetRedlNotRenderableInfo();
            const rawLayout = {
                ...pageRawLayout[pathname],
                structure: newStructure,
                ...(newAllData && { allData: newAllData })
            };
            const rawLayoutMD5 = crypto.createHash('md5').update(JSON.stringify(rawLayout)).digest('hex');
            let slugs = pathname.split('/');
            slugs[0] = 'web';
            const data = await getData({
                queryKey: { type: 'layout', context: { query: { editMode: true } }, options: { slugs: slugs, layout: rawLayoutMD5 } },
                layoutOnTheFly: rawLayout
            });
            let renderedByServer = data?.props;
            const structureRenderedByServer = renderedByServer?.layout?.structure;
            const allDataByServer = renderedByServer?.rawLayout?.allData;
            const rawLayoutByServer = renderedByServer?.rawLayout;

            setPageMetadata((prevState) => {
                if (prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: {
                            ...prevState[pathname],
                            structure: structureRenderedByServer,
                            allData: allDataByServer,
                            editId: prevState[pathname].editId + 1,
                            isReset: isReset || false,
                            renderThumbnails: renderThumbnails
                        }
                    };
                }
                return prevState;
            });
            setPageRawLayout((prevState) => {
                if (prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: rawLayoutByServer
                    };
                }
                return prevState;
            });
        };

        const _getThumbnailsIdxToEdit =  (start, end)  => {
            const step = start < end ? 1 : -1;
            return Array.from({ length: Math.abs(end - start) + 1 }, (_, i) => start + i * step);
        };

        const _moveRedlBlocOnPageRawLayout = (pathname, currentIdx, newIdx) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;

            const zonaEditable = getZonaEditable(structure);
            if (!zonaEditable) return;

            const removedRedlBloc = __pullAt(zonaEditable.children, currentIdx)[0];
            const newZonaEditableChildren = newIdx < zonaEditable.children.length
                ? [...zonaEditable.children.slice(0, newIdx), removedRedlBloc, ...zonaEditable.children.slice(newIdx)]
                : [...zonaEditable.children, removedRedlBloc];
            const newStructure = [...structure];
            _setZonaEditableChildren(newStructure, newZonaEditableChildren)

            const newAllData = _updateSources(pathname, {}, zonaEditable)

            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        allData: newAllData,
                        structure: newStructure
                    }
                };
            });
            setIsPageRawLayoutShouldBeStored(true);

            _renderRawLayoutOnServer(pathname, newStructure, newAllData, false, _getThumbnailsIdxToEdit(currentIdx + 1, newIdx + 1));
        };

        const _addRedlBlocOnPageRawLayout = (pathname, newBlock, newIdx, sources) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;

            const zonaEditable = getZonaEditable(structure);
            if (!zonaEditable) return;

            const newZonaEditableChildren = [...zonaEditable.children.slice(0, newIdx), newBlock, ...zonaEditable.children.slice(newIdx)];
            const newStructure = [...structure];
            _setZonaEditableChildren(newStructure, newZonaEditableChildren)

            const newAllData = _updateSources(pathname, sources, zonaEditable)

            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        allData: newAllData,
                        structure: newStructure
                    }
                };
            });
            setIsPageRawLayoutShouldBeStored(true);

            _renderRawLayoutOnServer(pathname, newStructure, newAllData);
        };

        const _editRedlBlocOnPageRawLayout = (pathname, newBlock, newIdx, sources) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;
            
            const zonaEditable = getZonaEditable(structure);
            if (!zonaEditable) return;

            const newZonaEditableChildren = [...zonaEditable.children.slice(0, newIdx), newBlock, ...zonaEditable.children.slice(newIdx + 1)];
            const newStructure = [...structure];
            _setZonaEditableChildren(newStructure, newZonaEditableChildren)

            const newAllData = _updateSources(pathname, sources, zonaEditable)

            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        allData: newAllData,
                        structure: newStructure
                    }
                };
            });
            setIsPageRawLayoutShouldBeStored(true);

            _renderRawLayoutOnServer(pathname, newStructure, newAllData);
        };

        const _deleteRedlBlocOnPageRawLayout = (pathname, currentIdx) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;

            const zonaEditable = getZonaEditable(structure);
            if (!zonaEditable) return;

            const newZonaEditableChildren = [
                ...zonaEditable.children.slice(0, currentIdx),
                ...zonaEditable.children.slice(currentIdx + 1)
            ];

            const newStructure = [...structure];
            _setZonaEditableChildren(newStructure, newZonaEditableChildren)

            const newAllData = _updateSources(pathname, {}, zonaEditable)

            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        allData: newAllData,
                        structure: newStructure
                    }
                };
            });
            setIsPageRawLayoutShouldBeStored(true);

            _renderRawLayoutOnServer(pathname, newStructure, newAllData);
        };

        const moveRedlBloc = (pathname, blocIdx, newIndex) => {
            const [structure, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            if (currentIdx < 0) return;

            const newIdx = newIndex < 0 ? 0 : newIndex >= structure.length ? structure.length - 1 : newIndex;
            _moveRedlBlocOnPageRawLayout(pathname, currentIdx, newIdx);
        };

        const addRedlBloc = (pathname, newBlock, newIndex, sources) => {
            _addRedlBlocOnPageRawLayout(pathname, newBlock, newIndex, sources);
        };

        const editRedlBloc = (pathname, newBlock, newIndex, sources) => {
            _editRedlBlocOnPageRawLayout(pathname, newBlock, newIndex, sources);
        };

        const deleteRedlBloc = (pathname, blocIdx) => {
            const [_structure, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            if (currentIdx < 0) return;

            _deleteRedlBlocOnPageRawLayout(pathname, currentIdx);
        };

        const _updateSources = (pathname, sources, zonaEditable) => {
            // update data sources
            const allData = pageRawLayout[pathname]?.allData;

            __mapKeys(sources, (value, key) => {
                __set(allData, `[0].main[${key}]`, value);
            });

            // actualitzar les fonts de dades dels preconfigurats per sincronitzar-los amb allData
            _updateSourcesInBlocREDLs(zonaEditable, sources);

            return allData;
        }

        const _updateSourcesInBlocREDLs = (component, allData) => {
            if (component?.sources) {
                __mapKeys(allData, (value, key) => {
                    if (component.sources[key]) {
                        component.sources[key] = value;
                    } 
                });
            }
            if (Array.isArray(component.children)) {
                component.children.map((child) => {
                    _updateSourcesInBlocREDLs(child, allData)
                });
            }
        };

        const setRedlNotRenderableInfo = (dataId, rootIndex, componentName, blocIdx) => {
            setPageNotRenderableInfo((prevState) => {
                const newState = { ...prevState };
                __set(newState, [dataId, rootIndex], { componentName, blocIdx, rootIndex });
                return newState;
            });
            setShowNotRenderableInfo(true);
        };

        const setRedlRenderableInfo = (pathname, newStructure, isReset) => {
            if (newStructure) {
                setPageRawLayout((prevState) => {
                    return {
                        ...prevState,
                        [pathname]: {
                            ...prevState[pathname],
                            structure: __cloneDeep(newStructure)
                        }
                    };
                });
                _renderRawLayoutOnServer(pathname, newStructure, undefined, isReset);
            }
        };

        return {
            setRedlPageMetadata,
            getRedlPageMetadata,
            getRedlBlocMetadata,
            setRedlPageRawLayout,
            getRedlPageRawLayout,
            getRedlBlocRawLayout,
            moveRedlBloc,
            addRedlBloc,
            editRedlBloc,
            deleteRedlBloc,
            setRedlNotRenderableInfo,
            setRedlRenderableInfo,
            setIsPageRawLayoutShouldBeStored,
            removeRenderThumbnail,
            getZonaEditable
        };
    }, [pageMetadata, pageRawLayout]);

    return (
        <RedlMetadataContext.Provider value={publicApi}>
            {showNotRenderableInfo && <RedlNotRenderableInfo pageNotRenderableInfo={pageNotRenderableInfo} />}
            {props.children}
        </RedlMetadataContext.Provider>
    );
};

export default RedlMetadataProvider;